import wretch from 'wretch'

import { unzipb64Payload } from '../webapp-lib/pathspot-react'
const controller = new AbortController()
export const abortController = controller

const w = wretch()
  .content('application/json')
  .catcher('AbortError', (err) => {
    //console.log("Aborting all previous requests.")
    //throw err;
  })
  .catcher('Error', (err) => {
    console.log('Error occured: ', err)
    throw err
  })
  .catcher('TypeError', (err) => {
    console.log('TypeError occured: ', err)
    throw err
  })
  .catcher(400, (err) => {
    console.log('400 Error occured - Access token not valid. ', err)
    throw err
  })
  .catcher(401, (err) => {
    console.log('401 Error occured: ', err)
    throw err
  })
  .catcher(500, (err) => {
    console.log('500 Error occured: ', err)
    throw err
  })
  .catcher(504, (err) => {
    console.log('504 Error occured: Failed to fetch due to timeout.', err)
    throw err
  })

export const api = {
  token: '',
  abortController: new AbortController(),

  setToken: function (token: string) {
    this.token = token
  },
  getToken: function () {
    return this.token
  },
  noAuth: function () {
    return w
  },

  withAuth: function () {
    return w.auth(this.token).signal(this.abortController)
  },

  withAuthAndNoSignal: function () {
    return w.auth(this.token)
  },

  abortAllRequests: function () {
    this.abortController.abort()
    this.abortController = new AbortController() //new controller needs to be set up to abort future requests
  },
  zjson: function (json: any) {
    // console.log('json.data', json.data)
    const unzippedData = unzipb64Payload(json.data)
    // console.log('json.unizipped payload', unzippedData)
    return json && json.data ? { data: unzippedData ? unzippedData : json.data } : json
  },
}

export const authFetcher =
  <T>(token: string) =>
  (url: string): Promise<T> =>
    api
      .noAuth()
      .auth(token)
      .url(url)
      .get()
      .json()
      .then(api.zjson)
      .then((resp) => resp.data)
